@import '@/styles/mixins/_breakpoints.scss';
@import '@/styles/variables/_breakpoints.scss';

.logos {

    // logos__row
    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1240px;
        margin: auto;
        width: calc(100% - 162px);
        padding: 84px 0 60px 0;

        @include breakpoint-up('sm') {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 48px 0 48px 0;
        }
    }

    // logos__img
    &__img {
        position: relative;
        width: 100%;
        max-width: 213px;
        min-width: 130px;
        min-height: 28px;
        margin: 0 auto;

        @include breakpoint-up('sm') {
            max-width: none;
            min-width: 0;
        }

        &:not(:first-child) {
            margin-top: 36px;

            @include breakpoint-up('sm') {
                margin-top: 0;
            }
        }
    }
}

.logos--subscription {
    .logos__row {
        min-width: 340px;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(4, 1fr);
        max-width: 1240px;
        margin: 0 auto;
        margin-top: 32px;
        margin-bottom: 38px;
        width: calc(100% - 162px);
        padding: 84px 0 60px 0;
        align-items: baseline;

        @include breakpoint-down('xs') {
            grid-template-columns: repeat(2, 1fr);
            margin-top: -24px;
            margin-bottom: 0px;
            padding: 0px;
        }
        padding: 0px 0 24px 0;
    }
}


.logos__img--subscription {
    min-height: 100px;
}